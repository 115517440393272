














































































































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import CommonUtils from '@/utils/common-utils';
import ld from 'lodash';

const settings = namespace('settings');
const schoolprofile = namespace('schoolprofile');
const teachers = namespace('teachers');
const classes = namespace('classes');
const email = namespace('email');

@Component
export default class StudentJoinCodes extends Vue {
  @Prop({ type: Boolean })
  value!: boolean;

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('close');
  }

  @Prop({ type: String })
  viewMode!: string;

  headersJoin = [
    { text: this.$t('classLabel'), value: 'class', width: 120 },
    { text: this.$t('joinCodeLabel'), value: 'code', width: 140 },
    { text: this.$t('joinURLLabel'), value: 'url', width: 350 }
  ];

  selectOptions:any = {
    adminYears: [],
    teachers: [],
    teacherYears: []
  }

  selectedRows: any = [];
  classes:any = [];
  studentAccountsEnabled = false;
  studentAccountsPrevented = false;

  selectedAdminYear:any = '';
  selectedTeacher:any = '';
  selectedTeacherYear:any = '';

  joinClassesAdminT:any = [];
  joinClassesAdminY:any = [];
  selectedRowsAdminT: any = [];
  selectedRowsAdminY: any = [];
  districtViewTeachers: any = [];
  viewBy = 'Y';

  @settings.Action
  loadAdminSchoolYears!: () => Promise<any>;

  @settings.Action
  editOtherSettings!: (param?: any) => Promise<any>;

  @settings.State
  adminSchoolYears!: Array<any>;

  @settings.Getter
  getCurrentSchoolYearId!: any;

  @settings.Getter
  getSchoolSettings!: any;

  @settings.Getter
  getOtherSettings!: any;

  @teachers.Getter
  getAdminItems!: any;

  @teachers.Getter
  getTeachersAll!: any;

  @teachers.Getter
  getAdminItemYears!: any;

  @classes.Getter
  getSchoolYearClassItems!: any;

  @classes.Getter('getClassItems')
  classList!: Array<any>;

  @classes.Action
  loadSchoolYearClasses!: (param:number) => Promise<any>;

  @classes.Action
  loadTeachersClasses!: (params: any) => Promise<any>;

  @email.Action
  sendEmail!: (param?: any) => Promise<any>;

  @schoolprofile.Action
  updateSchoolSettings!: (params: any) => Promise<any>;

  @Watch('value')
  init() {
    if (this.value === false) {
      return;
    }
    CommonUtils.showLoading();
    this.studentAccountsPrevented = this.getSchoolSettings.primarySchool ? this.getSchoolSettings.primarySchool.settings.preventCreateStudents : false;
    if (this.viewMode === 'T') {
      this.studentAccountsEnabled = this.getOtherSettings.allowNewStudentAccounts;
      this.initTeacherView();
    } else {
      this.initDistrictView();
      this.loadTeacherOptions();
    }
    CommonUtils.hideLoading();
  }

  generateClassJoinUrl(code: any) {
    return window.location.protocol + '//' + window.location.host + '?sc=' + code;
  }

  // TEACHER VIEW
  initTeacherView() {
    this.classes = [];
    for (const i in this.classList) {
      this.classes.push({ class: this.classList[i].text, code: this.classList[i].value, url: this.generateClassJoinUrl(this.classList[i].value) })
    }
    this.selectedRows = this.classes;
    CommonUtils.hideLoading();
  }

  toggleStudentCreation() {
    CommonUtils.showLoading();
    this.editOtherSettings({ allowNewStudentAccounts: this.studentAccountsEnabled }).then(CommonUtils.hideLoading).catch(() => {
      this.studentAccountsEnabled = !this.studentAccountsEnabled
    });
  }

  toggleStudentPrevention() {
    CommonUtils.showLoading();
    const settings = ld.cloneDeep(this.getSchoolSettings.primarySchool.settings);
    settings.preventCreateStudents = this.studentAccountsPrevented;
    this.updateSchoolSettings(settings).then(CommonUtils.hideLoading).catch(() => {
      this.studentAccountsPrevented = !this.studentAccountsPrevented;
    })
  }

  // ADMIN VIEW (Individual Teacher)
  loadTeacherOptions() {
    for (const i in this.getAdminItems) {
      this.selectOptions.teachers.push(this.getAdminItems[i])
    }
    this.selectedTeacher = this.selectOptions.teachers[0];
  }

  @Watch('selectedTeacher')
  loadYearOptions() {
    this.selectOptions.teacherYears = [];
    const yearsByTeacher = this.getAdminItemYears(this.selectedTeacher.value)
    for (const i in yearsByTeacher) {
      this.selectOptions.teacherYears.push({ yearId: yearsByTeacher[i].yearId, yearName: yearsByTeacher[i].yearName })
    }
    this.selectedTeacherYear = this.selectOptions.teacherYears[0]
  }

  @Watch('selectedTeacherYear')
  updateTeacherView() {
    if (!this.selectedTeacherYear) {
      return;
    }
    CommonUtils.showLoading();
    this.joinClassesAdminT = [];
    this.loadTeachersClasses({ yearId: this.selectedTeacherYear.yearId, teacherId: this.selectedTeacher.value }).then((d) => {
      for (const i in d.data.classes) {
        const curClass = d.data.classes[i];
        this.joinClassesAdminT.push({ class: curClass.cN, code: curClass.cId, url: this.generateClassJoinUrl(curClass.cId) });
      }
      this.selectedRowsAdminT = this.joinClassesAdminT;
      CommonUtils.hideLoading();
    })
  }

  // ADMIN VIEW (Year)
  initDistrictView() {
    this.loadAdminSchoolYears().then(() => {
      for (const i in this.adminSchoolYears) {
        this.selectOptions.adminYears.push(this.adminSchoolYears[i]);
      }
      this.selectedAdminYear = this.selectOptions.adminYears[0];
    })
  }

  @Watch('selectedAdminYear')
  updateDistrictView() {
    CommonUtils.showLoading();
    this.joinClassesAdminY = [];
    this.districtViewTeachers = [];
    for (const j in this.getAdminItems) {
      const teachersYears = this.getAdminItemYears(this.getAdminItems[j].value);
      Promise.all(
        teachersYears.map((curYear: any) => {
          if (!this.selectedAdminYear) {
            return Promise.resolve();
          }
          if (curYear.parentYearId === this.selectedAdminYear.yearId) {
            const teacherObj = { teacherId: curYear.teacherId, yearId: curYear.yearId, yearName: curYear.yearName, teacherName: this.getAdminItems[j].text };
            this.districtViewTeachers.push(teacherObj);
            return this.loadTeachersClasses({ yearId: curYear.yearId, teacherId: curYear.teacherId }).then((d) => {
              for (const i in d.data.classes) {
                const curClass = d.data.classes[i];
                this.joinClassesAdminY.push({ class: curClass.cN, code: curClass.cId, url: this.generateClassJoinUrl(curClass.cId), teacher: teacherObj });
              }
            })
          }
        })
      ).then(() => {
        this.selectedRowsAdminY = this.joinClassesAdminY;
        CommonUtils.hideLoading();
      })
    }
  }

  unselectAll(teacherYear: any) {
    this.selectedRowsAdminY = this.selectedRowsAdminY.filter((r: any) => r.teacher.teacherId !== teacherYear.teacherId || r.teacher.yearId !== teacherYear.yearId);
  }

  // BEGIN BUTTONS
  joinEmailTable(selectedRows: any, allClasses: any) {
    const selectedJoinCodes = selectedRows.map((a: any) => a.code);
    let message = this.$t('classJoinHeader').toString();
    if (selectedJoinCodes.length === allClasses.length) {
      message = message + this.$t('classJoinAll');
      const combinedCode = 'Y' + this.getCurrentSchoolYearId;
      message += this.$t('classJoinCodeRow', { name: this.$t('allClassesLabel'), code: combinedCode, url: this.generateClassJoinUrl(combinedCode) });
    } else {
      message = message + this.$t('classJoinSelected');
      const combinedCode = selectedJoinCodes.toString();
      message += this.$t('classJoinCodeRow', { name: this.$t('selectedClassesLabel'), code: combinedCode, url: this.generateClassJoinUrl(combinedCode) });
    }
    message = message + this.$t('classJoinSubHeader');
    for (const i in selectedRows) {
      const curRow = selectedRows[i];
      message += this.$t('classJoinCodeRow', { name: curRow.class, code: curRow.code, url: curRow.url });
    }
    return message + this.$t('classJoinEnd');
  }

  emailCodes() {
    let message = '';
    const subject = this.$t('generatedCodesLabel');
    if (this.viewMode === 'T') {
      if (CommonUtils.isNotEmpty(this.selectedRows)) {
        message = this.joinEmailTable(this.selectedRows, this.classes);
      }
    } else if (this.viewBy === 'T') {
      if (CommonUtils.isNotEmpty(this.selectedRowsAdminT)) {
        message = this.$t('classJoinYearHeader', { yearName: this.selectedTeacherYear.yearName, teacherName: this.selectedTeacher.text }).toString();
        message += this.joinEmailTable(this.selectedRowsAdminT, this.joinClassesAdminT)
      }
    } else {
      for (const i in this.districtViewTeachers) {
        const curTeacher = this.districtViewTeachers[i];
        const associatedSelected = this.selectedRowsAdminY.filter((a: any) => a.teacher === curTeacher);
        const associatedRows = this.joinClassesAdminY.filter((a: any) => a.teacher === curTeacher);
        if (CommonUtils.isNotEmpty(associatedSelected)) {
          message += this.$t('classJoinYearHeader', { yearName: curTeacher.yearName, teacherName: curTeacher.teacherName });
          message += this.joinEmailTable(associatedSelected, associatedRows);
        }
      }
    }

    this.sendEmail({ message: message, subject: subject });
    this.$emit('close');
  }

  joinCodesTeacher(selectedRows: any, allClasses: any) {
    let message = '';
    const selectedJoinCodes = selectedRows.map((a: any) => a.code);

    let combinedCode = '';
    if (selectedJoinCodes.length === allClasses.length) {
      message += this.$t('joinAllHead') + '\n';
      if (this.viewMode === 'T') {
        combinedCode = 'Y' + this.getCurrentSchoolYearId;
      } else if (this.viewBy === 'T') {
        combinedCode = 'Y' + this.selectedTeacherYear.yearId;
      } else {
        combinedCode = 'Y' + selectedRows[0].teacher.yearId;
      }
    } else {
      message += this.$t('joinSelectedHead') + '\n';
      combinedCode = selectedJoinCodes.toString()
    }
    message += this.$t('joinCodeLabel') + ': ' + combinedCode + '\n';
    message += this.$t('joinURLLabel') + ': ' + this.generateClassJoinUrl(combinedCode) + '\n\n';

    message += this.$t('joinIndividualHead') + '\n';
    for (const i in selectedRows) {
      const curRow = selectedRows[i]
      message += this.$t('classLabel') + ': ' + curRow.class + '\n';
      message += this.$t('joinCodeLabel') + ': ' + curRow.code + '\n';
      message += this.$t('joinURLLabel') + ': ' + curRow.url + '\n\n';
    }
    return message;
  }

  get joinCodesText() {
    let message = '';
    if (this.viewMode === 'T') {
      if (CommonUtils.isNotEmpty(this.selectedRows)) {
        message = this.joinCodesTeacher(this.selectedRows, this.classes);
      }
    } else if (this.viewBy === 'T') {
      if (CommonUtils.isNotEmpty(this.selectedRowsAdminT)) {
        message = this.$t('classJoinYearHeader', { yearName: this.selectedTeacherYear.yearName, teacherName: this.selectedTeacher.text }) + '\n\n';
        message += this.joinCodesTeacher(this.selectedRowsAdminT, this.joinClassesAdminT);
      }
    } else {
      for (const i in this.districtViewTeachers) {
        const curTeacher = this.districtViewTeachers[i];
        const associatedSelected = this.selectedRowsAdminY.filter((a: any) => a.teacher === curTeacher);
        const associatedRows = this.joinClassesAdminY.filter((a: any) => a.teacher === curTeacher);
        if (CommonUtils.isNotEmpty(associatedSelected)) {
          message += this.$t('classJoinYearHeader', { yearName: curTeacher.yearName, teacherName: curTeacher.teacherName }) + '\n\n';
          message += this.joinCodesTeacher(associatedSelected, associatedRows) + '\n\n\n';
        }
      }
    }
    return message;
  }

  copyCodes() {
    const message = this.joinCodesText;
    navigator.clipboard.writeText(message);
    this.$emit('close');
  }

  downloadCodes() {
    const message = this.joinCodesText;
    const FileSaver = require('file-saver');
    const blob = new Blob([message], { type: 'text/plain;charset=utf-8' });
    FileSaver.saveAs(blob, 'joinCodes.txt');
    this.$emit('close');
  }
}
