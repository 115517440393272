





































































































































































































































import StudentAssignment from '@/components/students/StudentAssignment.vue';
import StudentJoinCodes from '@/components/students/StudentJoinCodes.vue';
import StudentTags from '@/components/students/StudentTags.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { grades, tableWidths } from '../../constants'
import { namespace } from 'vuex-class';
import CommonUtils from '@/utils/common-utils';
import Confirm from '@/components/core/Confirm.vue';
import DateTimeUtils from '@/utils/date-time-utils';
import StudentUtils from '@/utils/student-utils';
import { VForm } from 'vuetify/lib';
import PageLifeCycleMixin from '@/mixins/page-lifecycle-mixin';
import RosteredItemIndicator from '@/components/integration/RosteredItemIndicator.vue';

const students = namespace('students');
const studentTags = namespace('studenttags');
const settings = namespace('settings');
const teachers = namespace('teachers');
const integration = namespace('integration');
const studentassignment = namespace('studentassignment');

@Component({
  mixins: [PageLifeCycleMixin],
  components: {
    StudentAssignment,
    StudentJoinCodes,
    StudentTags,
    RosteredItemIndicator
  }
})
export default class Students extends Vue {
    $refs!: {
      confirm: Confirm,
      promoteForm: InstanceType<typeof VForm>,
      CSVEditorForm: InstanceType<typeof VForm>,
      table: any,
      studentAssignment: any;
    }

    headers = [
      { text: '', value: 'photo', width: tableWidths.photo, mode: ['T', 'A'] },
      { text: this.$t('lastNameLabel'), value: 'lastName', width: tableWidths.longText, mode: ['T', 'A'] },
      { text: this.$t('firstNameLabel'), value: 'firstName', width: tableWidths.longText, mode: ['T', 'A'] },
      { text: this.$t('typeLabel'), value: 'authorType', width: tableWidths.shortText, mode: ['T', 'A'] },
      { text: this.$t('tagsLabel'), value: 'tags', width: tableWidths.shortText, mode: ['T', 'A'] },
      { text: '', value: 'roster-indicator', width: tableWidths.shortText, align: 'start', sortable: false, mode: ['T', 'A'] },
      { value: 'spacer', sortable: false, mode: ['T', 'A'] },
      { width: tableWidths.action3, sortable: false, value: 'action', mode: ['T', 'A'] }
    ];

    localRules = {
      nonEmpty: [(v: any) => !!v || ' ']
    }

    assigningStudents = false;
    allStudents: Array<any> = [];
    search = '';
    editMode = false;
    selectedRows: any = [];
    selectedRowsJoin: any = [];
    maxGrade:any = null;
    showLevel = this.gradesList[0];
    tableHeight: any = '';
    removeStudentId = 0;
    removeSubjectId = 0;
    removeSelectedSubjectId = 0;
    removeStudentName = '';
    removeSubjectName = '';
    showRemoveSuccess = false;

    dialog = {
      import: false,
      promote: false,
      joinCodes: false,
      tagsAssignment: false,
      tagsEditor: false
    }

    errorUploadFile = false;
    uploadedFile: any = null;
    localSlideGroupModel = null;

    @students.Action
    loadStudents!: (param?: any) => Promise<any>;

    @students.Action
    deleteStudent!: (param?: any) => Promise<any>;

    @students.Action
    deleteStudents!: (param?: any) => Promise<any>;

    @students.Action
    uploadStudents!: (param?: any) => Promise<any>;

    @students.Action
    getPromoteDetails!: (param?: any) => Promise<any>;

    @students.Action
    promoteStudents!: (param?: any) => Promise<any>;

    @students.Getter
    getStudents!: any[];

    @students.Getter
    getListLoading!: boolean;

    @settings.Getter('getUserMode')
    userMode!: string;

    @settings.Getter('getSchoolId')
    schoolId!: number;

    @settings.Getter('getShowSnackbarNotifications')
    showSnackbarNotifications!: boolean;

    @studentTags.Action
    loadTags!: (param?: any) => Promise<any>;

    @students.Action
    addTag!: (param?: any) => Promise<any>;

    @students.Action
    removeTag!: (param?: any) => Promise<any>;

    @studentTags.Getter
    getTags!: Array<any>;

    @settings.Getter('getPrimarySchool')
    primarySchool!: any;

    @settings.Getter('getSchools')
    schools!: any;

    @teachers.Action
    loadTeachers!: () => Promise<any>;

    @integration.Getter
    isStudentRostered!: (param: any) => boolean;

    @integration.Getter
    isSchoolRostered!: (param: any) => boolean;

    @integration.Getter
    haveRosteredItems!: boolean;

    @studentassignment.Action
    removeStudentFromClass!: (param?: any) => Promise<any>;

    oldGradeLevelFilter = -999;

    get haveTeachers() {
      return this.$store.state.teachers.haveTeachers;
    }

    set haveTeachers(value: boolean) {
      this.$store.commit('teachers/setHaveTeachers', value);
    }

    get currentSchoolId() {
      return this.$store.state.teachers.currentSchoolId || this.primarySchool.schoolId;
    }

    set currentSchoolId(val:any) {
      this.$store.commit('teachers/setCurrentSchoolId', val);
    }

    get haveMultipleSchools() {
      return this.schools.length > 1;
    }

    get schoolItems() {
      const that = this;
      return this.schools.map((s: any) => {
        return { text: s.schoolName, value: +s.schoolId, isItemRostered: that.isSchoolRostered(s) };
      });
    }

    get gradesList(): any {
      return grades;
    }

    get gradesListPromote() {
      return grades.filter((g) => !['-999', '80', '99'].includes(g.value))
    }

    get filteredHeaders() {
      return this.headers.filter((a) => {
        if (a.value === 'roster-indicator' && !this.haveRosteredItems) {
          return false;
        }
        return a.mode.includes(this.userMode);
      });
    }

    get localListLoading() {
      return this.getListLoading;
    }

    set localListLoading(value: boolean) {
      this.$store.commit('students/setListLoading', value);
    }

    get mobileBreakpoint() {
      return CommonUtils.getMobileBreakpointSize();
    }

    get isMobileMode() {
      return CommonUtils.isMobileMode();
    }

    get mobileTableClass() {
      return CommonUtils.mobileTableClass();
    }

    get mobileRowClass() {
      return CommonUtils.mobileRowClass();
    }

    get mobileHeaderClass() {
      return CommonUtils.mobileHeaderClass();
    }

    get mobileCellClass() {
      return CommonUtils.mobileCellClass();
    }

    get contentBreakpoint() {
      return CommonUtils.mainContentBreakpoint();
    }

    getStudentLabel(student: any) {
      return StudentUtils.getStudentLabel(student);
    }

    get studentAssignment() {
      return this.$refs.studentAssignment;
    }

    get localPage() {
      return CommonUtils.getPage(this.$route);
    }

    get allTags() {
      if (this.$currentUser.isAdmin) {
        return this.getTags.filter((t) => t.districtId || t.schoolId)
      } else {
        return this.getTags;
      }
    }

    get filteredStudents() {
      const allStudents: Array<any> = this.allStudents.map((s:any) => {
        s.isItemRostered = this.isStudentRostered(s);
        return s;
      });
      return allStudents.filter(s => this.gradeLevelFilter === null || this.gradeLevelFilter === undefined || this.gradeLevelFilter === -999 || s.gradeLevel === this.gradeLevelFilter);
    }

    get gradeLevelFilter() {
      if (this.showLevel) {
        if (typeof this.showLevel === 'object') {
          return +this.showLevel.value;
        } else if (Number.isInteger(+this.showLevel)) {
          return +this.showLevel;
        }
      }
      return -999;
    }

    get localremoveStudentId() {
      return this.removeStudentId;
    }

    set localremoveStudentId(v:number) {
      this.removeStudentId = +v;
    }

    get localremoveSubjectId() {
      return this.removeSubjectId;
    }

    set localremoveSubjectId(v:number) {
      this.removeSubjectId = +v;
    }

    get localremoveStudentName() {
      return this.removeStudentName;
    }

    set localremoveStudentName(v:string) {
      this.removeStudentName = v;
    }

    get localremoveSubjectName() {
      return this.removeSubjectName;
    }

    set localremoveSubjectName(v:string) {
      this.removeSubjectName = v;
    }

    @Watch('currentSchoolId')
    onCurrentSchoolIdChanged() {
      this.$nextTick(() => {
        if (this.haveTeachers && !this.assigningStudents) {
          this.haveTeachers = false;
          CommonUtils.showLoading();
          this.loadTeachers().then(() => {
            this.refresh(false);
          });
        }
      });
    }

    @Watch('assigningStudents')
    onAssigingStudentsChange() {
      if (this.assigningStudents) {
        this.$eventBus.$emit('closeSubPage', false);
      } else {
        this.$eventBus.$emit('openDefaultSubPage');
      }
    }

    @Watch('localPage')
    onPageChange() {
      this.assigningStudents = this.localPage === 'assign-students';
    }

    determinePermissions() {
      for (const i in this.allStudents) {
        this.allStudents[i].canEdit = (this.$currentUser.isAdmin || this.allStudents[i].authorType === 'T');
      }
    }

    getInitials(student: any) {
      return StudentUtils.getStudentInitials(student);
    }

    onUserModeChanged() {
      this.refresh(true);
    }

    created() {
      this.$nextTick(() => {
        this.$eventBus.$on('userModeChanged', this.onUserModeChanged);

        this.$eventBus.$on('refreshStudents', () => {
          this.refresh(false);
        });

        this.$eventBus.$on('resize', this.resizeTable);

        this.resizeTable();
      })
      this.assigningStudents = this.localPage === 'assign-students';
      this.refresh(true);
    }

    resizeTable() {
      this.$nextTick(() => {
        try {
          if (!this.assigningStudents) {
            const tableRef = this.$refs.table;
            this.tableHeight = window.innerHeight - tableRef.$el.getBoundingClientRect().top - 5;
          }
        } catch (e) {
        }
      });
    }

    destroyed() {
      this.$eventBus.$off('refreshStudents');
      this.$eventBus.$off('userModeChanged', this.onUserModeChanged);
      this.$eventBus.$off('resize', this.resizeTable);
    }

    @Watch('gradeLevelFilter')
    onGradeLevelFilterChange() {
      if ((this.gradeLevelFilter < 99 && this.oldGradeLevelFilter === 99) || (this.gradeLevelFilter === 99 && this.oldGradeLevelFilter < 99)) {
        this.refresh(false);
      }
    }

    refresh(initialLoad:boolean) {
      initialLoad ? CommonUtils.showLoading() : this.localListLoading = true;
      const promiseTags = this.loadTags();
      const params: any = { userMode: this.userMode };
      if (this.gradeLevelFilter !== -999) {
        params.gradeLevel = this.gradeLevelFilter;
      }
      if (this.$currentUser.isDistrictAdmin || this.haveMultipleSchools) {
        params.currentSchoolId = (this.$store.state.teachers.currentSchoolId || this.primarySchool.schoolId);
      }
      const promiseStudents = this.loadStudents(params).then(() => {
        this.oldGradeLevelFilter = this.gradeLevelFilter;
        this.allStudents = this.getStudents;
        this.determinePermissions();
      });
      Promise.all([promiseTags, promiseStudents]).then(() => {
        this.linkTags();
        CommonUtils.hideLoading();
        this.localListLoading = false;
      })
    }

    refreshTags() {
      this.localListLoading = true;
      this.dialog.tagsEditor = false;
      this.loadTags().then(() => {
        this.linkTags();
        this.localListLoading = false;
      })
    }

    // ADD, EDIT & DELETE
    confirmAddStudent() {
      if (this.$currentUser.isDualUser && this.$currentUser.isTeacher && !this.filteredStudents.find((s: any) => s.authorType === 'T')) {
        this.$refs.confirm.confirm({
          title: this.$t('addStudentLabel'),
          text: this.$t('addStudentWarning'),
          option1ButtonAlternativeText: this.$t('continueLabel')
        }).then((result) => {
          if (result === 1) {
            this.addStudent();
          }
        });
      } else {
        this.addStudent();
      }
    }

    addStudent() {
      this.$eventBus.$emit('openSubPage', {
        type: 'student',
        width: 600,
        modal: this.$currentUser.defaultEditorMode === 'modal',
        input: {
          action: 'A',
          grade: this.showLevel
        }
      });
    }

    openStudent(student: any) {
      if (student.canEdit) {
        this.editStudent(student);
      } else {
        this.viewStudent(student);
      }
    }

    editStudent(student: any) {
      this.$eventBus.$emit('openSubPage', {
        type: 'student',
        width: 600,
        modal: this.$currentUser.defaultEditorMode === 'modal',
        input: {
          action: 'E',
          student: student
        }
      });
    }

    viewStudent(student: any) {
      this.$eventBus.$emit('openSubPage', {
        type: 'student',
        width: 600,
        modal: this.$currentUser.defaultEditorMode === 'modal',
        input: {
          action: 'V',
          student: student
        }
      });
    }

    toggleEditMode() {
      this.editMode = !this.editMode;
      if (this.editMode === false) {
        this.selectedRows = [];
      }
    }

    get showDeleteButton() {
      return this.selectedRows.length > 0 && this.selectedRows.filter((s: any) => s.canEdit).length > 0;
    }

    deleteMultiplePopup() {
      const that = this;
      let warningConfirmation;
      if (this.selectedRows.filter((s: any) => !s.canEdit)) {
        warningConfirmation = this.$t('confirmDeleteEditableStudents');
      } else {
        warningConfirmation = this.$t('confirmDeleteMultipleGeneral', { type: this.$t('studentLabel') });
      }
      this.$refs.confirm.confirm({
        title: this.$t('studentLabel'),
        text: warningConfirmation,
        option1ButtonAlternativeText: this.$t('continueLabel')
      }).then((result) => {
        if (result === 1) {
          that.deleteMultiple();
        }
      });
    }

    deleteMultiple() {
      this.localListLoading = true;
      const studentIds = this.selectedRows.filter((s: any) => s.canEdit).map((r: any) => {
        return r.studentId;
      })
      this.deleteStudents({ studentIds: studentIds }).then(() => {
        this.$eventBus.$emit('deleteStudent', studentIds);
        this.selectedRows = [];
        this.refresh(false);
        if (this.showSnackbarNotifications) {
          this.$snotify.success(this.$t('statusMsg43') as string);
        }
        return Promise.resolve();
      })
    }

    deleteSinglePopup(student: any) {
      this.$refs.confirm.confirm({
        title: this.$t('studentLabel'),
        text: this.$t('confirmDeleteGeneral', { type: this.$t('studentLabel') }),
        option1ButtonAlternativeText: this.$t('continueLabel')
      }).then((result) => {
        if (result === 1) {
          this.localListLoading = true;
          this.deleteSingleStudent(student);
        }
      });
    }

    deleteSingleStudent(student: any) {
      this.deleteStudent({ studentId: student.studentId, userMode: this.userMode }).then(() => {
        this.$eventBus.$emit('deleteStudent', [student.studentId]);
        if (this.showSnackbarNotifications) {
          this.$snotify.success(this.$t('statusMsg43') as string);
        }
        return Promise.resolve();
      }).finally(() => { this.refresh(false) });
    }

    // TAG FUNCTIONS
    linkTags() {
      for (const i in this.allStudents) {
        const appliedTags = this.allStudents[i].tags;
        for (const j in appliedTags) {
          const tagInfo = this.allTags.find((t) => t.studentTagId === appliedTags[j].typeId);
          if (tagInfo) {
            appliedTags[j].color = tagInfo.color;
            appliedTags[j].tagText = tagInfo.tagText;
          }
        }
      }
    }

    addRemoveTag(tag: any, students: Array<any>) {
      const tagSelected = this.tagSelected(tag, students);
      if (tagSelected) {
        this.localListLoading = true;
        if (students.length > 1) {
          const editableStudents = students.filter((s: any) => {
            if (this.$currentUser.isAdmin) return true;
            const appliedTag = s.tags.find((t: any) => t.typeId === tag.studentTagId);
            return appliedTag.type === 'TAG';
          })
          if (editableStudents.length === 0) {
            this.localListLoading = false;
            return;
          }
          this.removeTag({ studentId: editableStudents.map((s: any) => s.studentId), tagId: tag.studentTagId }).then(() => {
            for (const i in editableStudents) {
              editableStudents[i].tags = editableStudents[i].tags.filter((t: any) => t.typeId !== tag.studentTagId);
            }
            this.localListLoading = false;
          })
        } else {
          if (!tagSelected.canEdit) {
            this.localListLoading = false;
            return;
          }
          this.removeTag({ studentId: students[0].studentId, tagId: tag.studentTagId }).then(() => {
            students[0].tags = students[0].tags.filter((t: any) => t.typeId !== tag.studentTagId);
            this.localListLoading = false;
          });
        }
      } else {
        this.localListLoading = true;
        if (students.length > 1) {
          const untaggedStudents = students.filter((s: any) => {
            const appliedTag = s.tags.find((t: any) => t.typeId === tag.studentTagId);
            return !appliedTag;
          })
          this.addTag({ studentId: untaggedStudents.map((s: any) => s.studentId), tagId: tag.studentTagId }).then(() => {
            for (const i in untaggedStudents) {
              const appendedTag = { studentId: untaggedStudents[i].studentId, studentTagId: tag.studentTagId, typeId: tag.studentTagId, type: 'TAG', canEdit: true, color: tag.color, tagText: tag.tagText };
              untaggedStudents[i].tags.push(appendedTag);
            }
            this.localListLoading = false;
          });
        } else {
          this.addTag({ studentId: students[0].studentId, tagId: tag.studentTagId }).then(() => {
            const appendedTag = { studentId: students[0].studentId, typeId: tag.studentTagId, type: 'TAG', canEdit: true, color: tag.color, tagText: tag.tagText };
            students[0].tags.push(appendedTag);
            this.localListLoading = false;
          });
        }
      }
    }

    tagSelected(tag: any, students: Array<any>) {
      if (students.length === 0) {
        return false;
      }
      let activeTags = [];
      if (students.length === 1) {
        activeTags = students[0].tags.map((t: any) => {
          t.canEdit = this.$currentUser.isAdmin || t.type === 'TAG';
          return t;
        })
      } else {
        activeTags = this.allTags.filter((t) => {
          const studentsWithTag = students.filter((s: any) => s.tags.find((st: any) => st.typeId === t.studentTagId));
          return studentsWithTag.length === students.length;
        })
      }
      return activeTags.find((t: any) => (t.typeId === tag.studentTagId || t.studentTagId === tag.studentTagId));
    }

    focusStatus(index: number) {
      try {
        if (index === -1) {
          this.focusActionButton();
        } else if (index >= this.allTags.length) {
          (this.$refs as any).manageStatusButton[0].$el.querySelector('.v-btn').focus();
        } else {
          (this.$refs as any)['status' + index][0].$el.querySelector('.v-btn').focus();
        }
      } catch (e) {
        console.log(this.$t('focusFailed'));
      }
    }

    focusActionButton() {
      try {
        (this.$refs as any).actionsButton.$el.focus();
      } catch (e) {
        console.log(this.$t('focusFailed'));
      }
    }

    // IMPORT FUNCTIONS
    openImport() {
      const form: any = this.$refs.CSVEditorForm;
      form.resetValidation();
      if (this.$currentUser.isAdmin) {
        this.$refs.confirm.confirm({
          title: this.$t('importStudentsLabel'),
          text: this.$t('importWarning'),
          option1ButtonAlternativeText: this.$t('continueLabel')
        }).then((result) => {
          if (result === 1) {
            this.dialog.import = true;
          }
        });
      } else {
        this.dialog.import = true;
      }
    }

    importStudents() {
      const form: any = this.$refs.CSVEditorForm;
      if (form.validate()) {
        this.dialog.import = false;
        this.localListLoading = true;
        this.uploadStudents({ file: this.uploadedFile, importStudentsUserMode: this.userMode }).then((responseData) => {
          const FileSaver = require('file-saver');
          const blob = new Blob([responseData.data], { type: 'text/plain;charset=utf-8' });
          FileSaver.saveAs(blob, 'StudentsAdded.txt');
          this.refresh(false);
        });
      }
    }

    downloadSudentSampleCSV() {
      const message = this.$t('addStrudentSampleCSV').toString();
      const FileSaver = require('file-saver');
      const blob = new Blob([message], { type: 'text/plain;charset=utf-8' });
      FileSaver.saveAs(blob, 'students.csv');
      this.dialog.joinCodes = false;
    }

    // PROMOTE FUNCTIONS
    openPromote() {
      const form: any = this.$refs.promoteForm;
      form.resetValidation();
      let promoteDetails: any;
      this.getPromoteDetails().then((d) => {
        promoteDetails = d.data;
        const daysBetween = DateTimeUtils.daysDiff(promoteDetails.lastPromote, DateTimeUtils.currentDateAddDays(0));
        if (daysBetween < 300) {
          this.$refs.confirm.confirm({
            title: this.$t('promoteStudentsLabel'),
            text: this.$t('promoteWarning', { date: promoteDetails.lastPromote }),
            option1ButtonAlternativeText: this.$t('continueLabel')
          }).then((result) => {
            if (result === 1) {
              this.dialog.promote = true;
              this.maxGrade = this.gradesList.filter((a:any) => a.value === promoteDetails.highestGradeLevel.toString())[0];
            }
          });
        } else {
          this.dialog.promote = true;
        }
      });
    }

    doPromote() {
      const form: any = this.$refs.promoteForm;
      if (form.validate()) {
        this.promoteStudents({
          schoolId: this.schoolId,
          highestGradeLevel: this.maxGrade.value
        }).then(() => {
          this.dialog.promote = false;
          this.refresh(false)
        })
      }
    }

    mounted() {
      this.$nextTick(() => {
        this.onAssigingStudentsChange();
      })
      this.localremoveStudentId = +this.$route.query.stid;
      this.localremoveSubjectId = +this.$route.query.rsid;
      this.removeSelectedSubjectId = this.localremoveSubjectId;
      this.localremoveStudentName = this.$route.query.stname + '';
      this.localremoveSubjectName = this.$route.query.sbj + '';
      if (this.localremoveStudentId !== 0 && this.localremoveSubjectId !== 0 && !Number.isNaN(this.localremoveStudentId) && !Number.isNaN(this.localremoveSubjectId)) {
        this.removeStudent();
      }
    }

    removeStudent() {
      this.$router.push({ path: 'assign-students' });
      this.$refs.confirm.confirm({
        title: this.$t('removeLabel'),
        text: this.$t('confirmRemoveText', { studentName: this.localremoveStudentName, className: this.localremoveSubjectName }),
        option1ButtonAlternativeText: this.$t('removeLabel')
      }).then((result) => {
        if (result === 1) {
          this.removeStudentFromClass({
            subjectId: this.localremoveSubjectId,
            studentId: this.localremoveStudentId
          }).then(() => {
            this.showRemoveSuccess = true;
          }).finally(() => {
            this.removeLocalSubjectid();
            this.removeSelectedSubjectId = this.localremoveSubjectId;
            this.studentAssignment.init();
          });
        }
      })
    }

    removeLocalSubjectid() {
      this.removeSelectedSubjectId = 0;
    }
}
