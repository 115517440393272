var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    { staticClass: "students", attrs: { color: "#ffffff" } },
    [
      _vm.$vuetify.breakpoint.smAndDown
        ? _c(
            "v-sheet",
            { staticClass: "mx-auto", attrs: { elevation: "0" } },
            [
              _vm.$currentUser.isDistrictAdmin || _vm.haveMultipleSchools
                ? _c(
                    "v-slide-group",
                    {
                      staticClass: "py-2",
                      attrs: { "center-active": "" },
                      model: {
                        value: _vm.localSlideGroupModel,
                        callback: function($$v) {
                          _vm.localSlideGroupModel = $$v
                        },
                        expression: "localSlideGroupModel"
                      }
                    },
                    [
                      _c(
                        "v-slide-item",
                        { key: 0 },
                        [
                          _c("v-autocomplete", {
                            staticClass: "ml-2 pill-mobile-filters",
                            staticStyle: {
                              "max-width": "200px",
                              "font-size": "12px"
                            },
                            attrs: {
                              rounded: "",
                              dense: "",
                              flat: "",
                              solo: "",
                              outlined: "",
                              "hide-details": "auto",
                              items: _vm.schoolItems
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function(data) {
                                    return [
                                      _c("span", [
                                        _vm._v(_vm._s(data.item.text))
                                      ]),
                                      _c("rostered-item-indicator", {
                                        key:
                                          data.item.value +
                                          data.item.isItemRostered,
                                        attrs: {
                                          itemType: "school",
                                          item: data.item
                                        }
                                      })
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              901267436
                            ),
                            model: {
                              value: _vm.currentSchoolId,
                              callback: function($$v) {
                                _vm.currentSchoolId = $$v
                              },
                              expression: "currentSchoolId"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              !_vm.assigningStudents
                ? _c(
                    "v-card",
                    {
                      staticClass: "rounded-0",
                      class: { "mt-5": _vm.$vuetify.breakpoint.mdAndUp },
                      attrs: { flat: "" }
                    },
                    [
                      _c("v-data-table", {
                        ref: "table",
                        attrs: {
                          headers: _vm.filteredHeaders,
                          items: _vm.filteredStudents,
                          search: _vm.search,
                          "item-key": "studentId",
                          height: _vm.tableHeight,
                          "fixed-header": "",
                          "show-select": _vm.editMode,
                          "hide-default-footer": "",
                          "disable-pagination": "",
                          loading: _vm.localListLoading,
                          "loading-text": _vm.$t("listLoadingMsg"),
                          "mobile-breakpoint": _vm.mobileBreakpoint
                        },
                        on: { "click:row": _vm.openStudent },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "body",
                              fn: function(ref) {
                                var items = ref.items
                                var isSelected = ref.isSelected
                                var select = ref.select
                                return [
                                  _c(
                                    "tbody",
                                    {
                                      ref: "listContainer",
                                      staticClass: "list-container"
                                    },
                                    _vm._l(items, function(item, index) {
                                      return _c(
                                        "tr",
                                        {
                                          key: index,
                                          class: _vm.mobileTableClass,
                                          staticStyle: { cursor: "pointer" },
                                          attrs: { tabindex: "0", role: "row" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              $event.stopPropagation()
                                              return _vm.openStudent(item)
                                            },
                                            keyup: function($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              ) {
                                                return null
                                              }
                                              $event.preventDefault()
                                              $event.stopPropagation()
                                              return _vm.openStudent(item)
                                            }
                                          }
                                        },
                                        [
                                          _vm.editMode
                                            ? _c(
                                                "td",
                                                {
                                                  class: _vm.mobileRowClass,
                                                  on: {
                                                    keyup: function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      select(
                                                        item,
                                                        !isSelected(item)
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm.isMobileMode
                                                    ? _c("div", {
                                                        class:
                                                          _vm.mobileHeaderClass
                                                      })
                                                    : _vm._e(),
                                                  _c("v-checkbox", {
                                                    class: _vm.mobileCellClass,
                                                    staticStyle: {
                                                      margin: "0px",
                                                      padding: "0px"
                                                    },
                                                    attrs: {
                                                      "input-value": isSelected(
                                                        item
                                                      ),
                                                      color: !_vm.$vuetify.theme
                                                        .dark
                                                        ? "#7a7a7a"
                                                        : "#ffffff",
                                                      "hide-details": "",
                                                      "aria-label": _vm.getStudentLabel(
                                                        item
                                                      )
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        select(
                                                          item,
                                                          !isSelected(item)
                                                        )
                                                      }
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "td",
                                            { class: _vm.mobileRowClass },
                                            [
                                              _vm.isMobileMode
                                                ? _c("div", {
                                                    class: _vm.mobileHeaderClass
                                                  })
                                                : _vm._e(),
                                              _c(
                                                "v-avatar",
                                                {
                                                  class: _vm.mobileCellClass,
                                                  attrs: {
                                                    color: "primary",
                                                    size: "2.5rem"
                                                  }
                                                },
                                                [
                                                  item.photoUrl &&
                                                  item.photoUrl != ""
                                                    ? _c("v-img", {
                                                        attrs: {
                                                          src: item.photoUrl
                                                        }
                                                      })
                                                    : _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "white--text"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.getInitials(
                                                                item
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            { class: _vm.mobileRowClass },
                                            [
                                              _vm.isMobileMode
                                                ? _c(
                                                    "div",
                                                    {
                                                      class:
                                                        _vm.mobileHeaderClass
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.headers[1].text
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                { class: _vm.mobileCellClass },
                                                [_vm._v(_vm._s(item.lastName))]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { class: _vm.mobileRowClass },
                                            [
                                              _vm.isMobileMode
                                                ? _c(
                                                    "div",
                                                    {
                                                      class:
                                                        _vm.mobileHeaderClass
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.headers[2].text
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                { class: _vm.mobileCellClass },
                                                [_vm._v(_vm._s(item.firstName))]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { class: _vm.mobileRowClass },
                                            [
                                              _vm.isMobileMode
                                                ? _c(
                                                    "div",
                                                    {
                                                      class:
                                                        _vm.mobileHeaderClass
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.headers[3].text
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              item.authorType == "T"
                                                ? _c(
                                                    "span",
                                                    {
                                                      class: _vm.mobileCellClass
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("teacherLabel")
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _c(
                                                    "span",
                                                    {
                                                      class: _vm.mobileCellClass
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("schoolLabel")
                                                        )
                                                      )
                                                    ]
                                                  )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { class: _vm.mobileRowClass },
                                            [
                                              _vm.isMobileMode
                                                ? _c(
                                                    "div",
                                                    {
                                                      class:
                                                        _vm.mobileHeaderClass
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.headers[4].text
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                { class: _vm.mobileCellClass },
                                                _vm._l(item.tags, function(
                                                  tag
                                                ) {
                                                  return _c(
                                                    "v-tooltip",
                                                    {
                                                      key: tag.typeId,
                                                      attrs: {
                                                        "open-delay": "0",
                                                        bottom: ""
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "mr-1",
                                                                      attrs: {
                                                                        color:
                                                                          tag.color
                                                                      }
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "fas fa-circle"
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(tag.tagText)
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                }),
                                                1
                                              )
                                            ]
                                          ),
                                          _vm.haveRosteredItems
                                            ? _c(
                                                "td",
                                                { class: _vm.mobileRowClass },
                                                [
                                                  _c(
                                                    "rostered-item-indicator",
                                                    {
                                                      key:
                                                        item.studentId +
                                                        item.isItemRostered,
                                                      attrs: {
                                                        itemType: "student",
                                                        item: item
                                                      }
                                                    }
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c("td", {
                                            class: _vm.mobileRowClass
                                          }),
                                          _c(
                                            "td",
                                            { class: _vm.mobileRowClass },
                                            [
                                              _vm.isMobileMode
                                                ? _c(
                                                    "div",
                                                    {
                                                      class:
                                                        _vm.mobileHeaderClass
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.headers[6].text
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                { class: _vm.mobileCellClass },
                                                [
                                                  _c(
                                                    "v-menu",
                                                    {
                                                      attrs: {
                                                        bottom: "",
                                                        "close-on-click": true,
                                                        "close-on-content-click": false,
                                                        maxWidth: 200
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        ref:
                                                                          "actionsButton",
                                                                        refInFor: true,
                                                                        staticClass:
                                                                          "mx-3 hover-button-primary",
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                          text:
                                                                            "",
                                                                          color:
                                                                            _vm.contentBreakpoint ===
                                                                            "xs"
                                                                              ? "primary"
                                                                              : "transparent"
                                                                        },
                                                                        on: {
                                                                          keyup: [
                                                                            function(
                                                                              $event
                                                                            ) {
                                                                              if (
                                                                                !$event.type.indexOf(
                                                                                  "key"
                                                                                ) &&
                                                                                _vm._k(
                                                                                  $event.keyCode,
                                                                                  "enter",
                                                                                  13,
                                                                                  $event.key,
                                                                                  "Enter"
                                                                                )
                                                                              ) {
                                                                                return null
                                                                              }
                                                                              $event.stopPropagation()
                                                                            },
                                                                            function(
                                                                              $event
                                                                            ) {
                                                                              if (
                                                                                !$event.type.indexOf(
                                                                                  "key"
                                                                                ) &&
                                                                                _vm._k(
                                                                                  $event.keyCode,
                                                                                  "right",
                                                                                  39,
                                                                                  $event.key,
                                                                                  [
                                                                                    "Right",
                                                                                    "ArrowRight"
                                                                                  ]
                                                                                )
                                                                              ) {
                                                                                return null
                                                                              }
                                                                              if (
                                                                                "button" in
                                                                                  $event &&
                                                                                $event.button !==
                                                                                  2
                                                                              ) {
                                                                                return null
                                                                              }
                                                                              return _vm.focusStatus(
                                                                                0
                                                                              )
                                                                            }
                                                                          ]
                                                                        }
                                                                      },
                                                                      "v-btn",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "tagLabel"
                                                                          )
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _c(
                                                        "v-list-item",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "ripple",
                                                              rawName:
                                                                "v-ripple.stop",
                                                              modifiers: {
                                                                stop: true
                                                              }
                                                            }
                                                          ],
                                                          staticClass:
                                                            "white student-tags pa-2",
                                                          attrs: {
                                                            tabindex: "0"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "pr-2"
                                                            },
                                                            [
                                                              _c(
                                                                "v-row",
                                                                {
                                                                  staticClass:
                                                                    "ma-0",
                                                                  attrs: {
                                                                    justify:
                                                                      "center"
                                                                  }
                                                                },
                                                                _vm._l(
                                                                  _vm.allTags,
                                                                  function(
                                                                    tag,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "pb-btn",
                                                                      {
                                                                        key:
                                                                          tag.studentTagId,
                                                                        ref:
                                                                          "status" +
                                                                          index,
                                                                        refInFor: true,
                                                                        attrs: {
                                                                          label:
                                                                            tag.tagText,
                                                                          icon:
                                                                            ""
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.addRemoveTag(
                                                                              tag,
                                                                              [
                                                                                item
                                                                              ]
                                                                            )
                                                                          },
                                                                          keyup: [
                                                                            function(
                                                                              $event
                                                                            ) {
                                                                              if (
                                                                                !$event.type.indexOf(
                                                                                  "key"
                                                                                ) &&
                                                                                _vm._k(
                                                                                  $event.keyCode,
                                                                                  "enter",
                                                                                  13,
                                                                                  $event.key,
                                                                                  "Enter"
                                                                                )
                                                                              ) {
                                                                                return null
                                                                              }
                                                                              return _vm.addRemoveTag(
                                                                                tag,
                                                                                [
                                                                                  item
                                                                                ]
                                                                              )
                                                                            },
                                                                            function(
                                                                              $event
                                                                            ) {
                                                                              if (
                                                                                !$event.type.indexOf(
                                                                                  "key"
                                                                                ) &&
                                                                                _vm._k(
                                                                                  $event.keyCode,
                                                                                  "down",
                                                                                  40,
                                                                                  $event.key,
                                                                                  [
                                                                                    "Down",
                                                                                    "ArrowDown"
                                                                                  ]
                                                                                )
                                                                              ) {
                                                                                return null
                                                                              }
                                                                              return _vm.focusActionButton.apply(
                                                                                null,
                                                                                arguments
                                                                              )
                                                                            },
                                                                            function(
                                                                              $event
                                                                            ) {
                                                                              if (
                                                                                !$event.type.indexOf(
                                                                                  "key"
                                                                                ) &&
                                                                                _vm._k(
                                                                                  $event.keyCode,
                                                                                  "up",
                                                                                  38,
                                                                                  $event.key,
                                                                                  [
                                                                                    "Up",
                                                                                    "ArrowUp"
                                                                                  ]
                                                                                )
                                                                              ) {
                                                                                return null
                                                                              }
                                                                              return _vm.focusActionButton.apply(
                                                                                null,
                                                                                arguments
                                                                              )
                                                                            },
                                                                            function(
                                                                              $event
                                                                            ) {
                                                                              if (
                                                                                !$event.type.indexOf(
                                                                                  "key"
                                                                                ) &&
                                                                                _vm._k(
                                                                                  $event.keyCode,
                                                                                  "right",
                                                                                  39,
                                                                                  $event.key,
                                                                                  [
                                                                                    "Right",
                                                                                    "ArrowRight"
                                                                                  ]
                                                                                )
                                                                              ) {
                                                                                return null
                                                                              }
                                                                              if (
                                                                                "button" in
                                                                                  $event &&
                                                                                $event.button !==
                                                                                  2
                                                                              ) {
                                                                                return null
                                                                              }
                                                                              return _vm.focusStatus(
                                                                                index +
                                                                                  1
                                                                              )
                                                                            },
                                                                            function(
                                                                              $event
                                                                            ) {
                                                                              if (
                                                                                !$event.type.indexOf(
                                                                                  "key"
                                                                                ) &&
                                                                                _vm._k(
                                                                                  $event.keyCode,
                                                                                  "left",
                                                                                  37,
                                                                                  $event.key,
                                                                                  [
                                                                                    "Left",
                                                                                    "ArrowLeft"
                                                                                  ]
                                                                                )
                                                                              ) {
                                                                                return null
                                                                              }
                                                                              if (
                                                                                "button" in
                                                                                  $event &&
                                                                                $event.button !==
                                                                                  0
                                                                              ) {
                                                                                return null
                                                                              }
                                                                              return _vm.focusStatus(
                                                                                index -
                                                                                  1
                                                                              )
                                                                            }
                                                                          ]
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs: {
                                                                              color:
                                                                                tag.color
                                                                            },
                                                                            domProps: {
                                                                              textContent: _vm._s(
                                                                                _vm.tagSelected(
                                                                                  tag,
                                                                                  [
                                                                                    item
                                                                                  ]
                                                                                )
                                                                                  ? "fas fa-check-circle"
                                                                                  : "fas fa-circle"
                                                                              )
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "pb-btn",
                                                                {
                                                                  ref:
                                                                    "manageStatusButton",
                                                                  refInFor: true,
                                                                  attrs: {
                                                                    icon: "",
                                                                    label: _vm.$t(
                                                                      "manageTagsLabel"
                                                                    )
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      _vm.dialog.tagsEditor = true
                                                                    },
                                                                    keyup: [
                                                                      function(
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          !$event.type.indexOf(
                                                                            "key"
                                                                          ) &&
                                                                          _vm._k(
                                                                            $event.keyCode,
                                                                            "enter",
                                                                            13,
                                                                            $event.key,
                                                                            "Enter"
                                                                          )
                                                                        ) {
                                                                          return null
                                                                        }
                                                                        _vm.dialog.tagsEditor = true
                                                                      },
                                                                      function(
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          !$event.type.indexOf(
                                                                            "key"
                                                                          ) &&
                                                                          _vm._k(
                                                                            $event.keyCode,
                                                                            "left",
                                                                            37,
                                                                            $event.key,
                                                                            [
                                                                              "Left",
                                                                              "ArrowLeft"
                                                                            ]
                                                                          )
                                                                        ) {
                                                                          return null
                                                                        }
                                                                        if (
                                                                          "button" in
                                                                            $event &&
                                                                          $event.button !==
                                                                            0
                                                                        ) {
                                                                          return null
                                                                        }
                                                                        return _vm.focusStatus(
                                                                          _vm
                                                                            .allTags
                                                                            .length -
                                                                            1
                                                                        )
                                                                      },
                                                                      function(
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          !$event.type.indexOf(
                                                                            "key"
                                                                          ) &&
                                                                          _vm._k(
                                                                            $event.keyCode,
                                                                            "down",
                                                                            40,
                                                                            $event.key,
                                                                            [
                                                                              "Down",
                                                                              "ArrowDown"
                                                                            ]
                                                                          )
                                                                        ) {
                                                                          return null
                                                                        }
                                                                        return _vm.focusActionButton.apply(
                                                                          null,
                                                                          arguments
                                                                        )
                                                                      },
                                                                      function(
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          !$event.type.indexOf(
                                                                            "key"
                                                                          ) &&
                                                                          _vm._k(
                                                                            $event.keyCode,
                                                                            "up",
                                                                            38,
                                                                            $event.key,
                                                                            [
                                                                              "Up",
                                                                              "ArrowUp"
                                                                            ]
                                                                          )
                                                                        ) {
                                                                          return null
                                                                        }
                                                                        return _vm.focusActionButton.apply(
                                                                          null,
                                                                          arguments
                                                                        )
                                                                      }
                                                                    ]
                                                                  }
                                                                },
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "fal fa-cog"
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  item.canEdit
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "mx-3 hover-button-primary",
                                                              attrs: {
                                                                small: "",
                                                                text: "",
                                                                color:
                                                                  _vm.contentBreakpoint ===
                                                                  "xs"
                                                                    ? "primary"
                                                                    : "transparent"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.editStudent(
                                                                    item
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "editLabel"
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "mx-3 hover-button-error",
                                                              attrs: {
                                                                text: "",
                                                                small: "",
                                                                color:
                                                                  _vm.contentBreakpoint ===
                                                                  "xs"
                                                                    ? "error"
                                                                    : "transparent"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.stopPropagation()
                                                                  return _vm.deleteSinglePopup(
                                                                    item
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "deleteLabel"
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "mx-3 hover-button-primary",
                                                              attrs: {
                                                                small: "",
                                                                text: "",
                                                                color:
                                                                  _vm.contentBreakpoint ===
                                                                  "xs"
                                                                    ? "primary"
                                                                    : "transparent"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.viewStudent(
                                                                    item
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "viewLabel"
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          277031271
                        ),
                        model: {
                          value: _vm.selectedRows,
                          callback: function($$v) {
                            _vm.selectedRows = $$v
                          },
                          expression: "selectedRows"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("student-assignment", {
        ref: "studentAssignment",
        attrs: {
          value: _vm.assigningStudents,
          showLevel: _vm.showLevel,
          selectedRemoveSubjectId: _vm.removeSelectedSubjectId
        },
        on: {
          "update:showLevel": function($event) {
            _vm.showLevel = $event
          },
          "update:show-level": function($event) {
            _vm.showLevel = $event
          },
          "remove-selected-id": _vm.removeLocalSubjectid,
          done: function($event) {
            _vm.assigningStudents = false
          }
        }
      }),
      _c(
        "v-form",
        { ref: "CSVEditorForm", attrs: { "lazy-validation": "" } },
        [
          _c(
            "pb-base-modal",
            {
              attrs: { maxWidth: 500, expandable: false, withActions: false },
              on: { apply: _vm.importStudents },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function() {
                    return [_vm._v(_vm._s(_vm.$t("importStudentsLabel")))]
                  },
                  proxy: true
                }
              ]),
              model: {
                value: _vm.dialog.import,
                callback: function($$v) {
                  _vm.$set(_vm.dialog, "import", $$v)
                },
                expression: "dialog.import"
              }
            },
            [
              _c(
                "v-container",
                { staticClass: "pb-7" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("p", [_vm._v(_vm._s(_vm.$t("importCSVMsg1")))]),
                          _c("p", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t("importCSVMsg2Gen", { type: "students" })
                              )
                            }
                          }),
                          _c("v-file-input", {
                            attrs: {
                              rules: _vm.localRules.nonEmpty,
                              clearable: "",
                              outlined: "",
                              "hide-details": "",
                              "prepend-inner-icon": "mdi-paperclip",
                              "prepend-icon": "",
                              accept: ".csv",
                              label: _vm.$t("csvFileLabel") + " (*.csv)",
                              placeholder: _vm.$t("csvFileLabel") + " (*.csv)",
                              error: _vm.errorUploadFile,
                              "aria-required": "true"
                            },
                            model: {
                              value: _vm.uploadedFile,
                              callback: function($$v) {
                                _vm.uploadedFile = $$v
                              },
                              expression: "uploadedFile"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-form",
        { ref: "promoteForm" },
        [
          _c(
            "pb-base-modal",
            {
              attrs: { maxWidth: 500, expandable: false, withActions: false },
              on: { apply: _vm.doPromote },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function() {
                    return [_vm._v(_vm._s(_vm.$t("promoteStudentsLabel")))]
                  },
                  proxy: true
                }
              ]),
              model: {
                value: _vm.dialog.promote,
                callback: function($$v) {
                  _vm.$set(_vm.dialog, "promote", $$v)
                },
                expression: "dialog.promote"
              }
            },
            [
              _c(
                "v-container",
                { staticClass: "pb-7" },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("div", [_vm._v(_vm._s(_vm.$t("promoteP1")))])
                      ]),
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("div", [_vm._v(_vm._s(_vm.$t("promoteP2")))])
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("div", [_vm._v(_vm._s(_vm.$t("promoteP3")))]),
                          _c("v-select", {
                            attrs: {
                              label: _vm.$t("gradeLevelLabel") + "*",
                              "aria-required": "true",
                              "display:inline-flex": "",
                              "return-object": "",
                              "hide-details": "auto",
                              items: _vm.gradesListPromote,
                              "item-text": "text",
                              outlined: "",
                              autofocus: ""
                            },
                            model: {
                              value: _vm.maxGrade,
                              callback: function($$v) {
                                _vm.maxGrade = $$v
                              },
                              expression: "maxGrade"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("student-tags", {
        attrs: { value: _vm.dialog.tagsEditor },
        on: { close: _vm.refreshTags }
      }),
      _c("student-join-codes", {
        attrs: { value: _vm.dialog.joinCodes, viewMode: _vm.userMode },
        on: {
          close: function($event) {
            _vm.dialog.joinCodes = false
          }
        }
      }),
      _c("pb-confirm", { ref: "confirm" }),
      _c("pb-confirm", { ref: "Removeconfirm" }),
      _c(
        "v-alert",
        {
          staticClass: "request-success-alert",
          attrs: {
            dense: "",
            dismissible: "",
            border: "left",
            type: "success",
            value: _vm.showRemoveSuccess,
            transition: "fade-transition"
          }
        },
        [_vm._v(" " + _vm._s(_vm.$t("removeSuccessLabel")) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }