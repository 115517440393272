var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.value
    ? _c(
        "v-card",
        {
          attrs: { flat: "" },
          on: {
            mouseenter: _vm.disableResizeable,
            mouseleave: _vm.enableResizeable,
          },
        },
        [
          _c(
            "v-row",
            {
              staticClass: "pt-4 pb-4 px-4",
              attrs: { justify: "center", "align-center": "" },
            },
            [
              _vm.$currentUser.isTeacher
                ? _c("div", [_vm._v(_vm._s(_vm.$t("assignDescTeacher")))])
                : _c("div", [_vm._v(_vm._s(_vm.$t("assignDescAdmin")))]),
            ]
          ),
          _vm.$vuetify.breakpoint.smAndDown || _vm.localIsMenuOverlapping
            ? _c(
                "v-sheet",
                { staticClass: "mx-auto", attrs: { elevation: "0" } },
                [
                  _c(
                    "v-slide-group",
                    { staticClass: "py-2", attrs: { "center-active": "" } },
                    [
                      _vm.$currentUser.isAdmin
                        ? _c(
                            "v-slide-item",
                            { key: 0 },
                            [
                              _c("school-year-selector", {
                                attrs: { slideItem: "", warnPastYears: "" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.$currentUser.isTeacher
                        ? _c(
                            "v-slide-item",
                            { key: 1 },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    transition: "scroll-y-transition",
                                    "offset-y": "",
                                    "max-height": "500",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "ml-2",
                                                    attrs: {
                                                      color: "primary",
                                                      outlined: "",
                                                      rounded: "",
                                                      small: "",
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.selectedClass.text
                                                    )
                                                  ),
                                                ]),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "ml-2",
                                                    attrs: { small: "" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "fal fa-chevron-down"
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    200108092
                                  ),
                                },
                                [
                                  _c(
                                    "v-list",
                                    _vm._l(_vm.classList, function (classItem) {
                                      return _c(
                                        "v-list-item",
                                        {
                                          key: classItem.value,
                                          class: {
                                            "primary--text v-list-item--active v-list-item--link theme--light v-list-item--highlighted":
                                              _vm.selectedClass.value ==
                                              classItem.value,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.selectedClass = classItem
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(classItem.text) + " "
                                          ),
                                        ]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-slide-item",
                        { key: 2 },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                transition: "scroll-y-transition",
                                "offset-y": "",
                                "max-height": "500",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "ml-2",
                                                attrs: {
                                                  color: "primary",
                                                  outlined: "",
                                                  rounded: "",
                                                  small: "",
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.showLevel.text)
                                              ),
                                            ]),
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "ml-2",
                                                attrs: { small: "" },
                                              },
                                              [_vm._v("fal fa-chevron-down")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                207918318
                              ),
                            },
                            [
                              _c(
                                "v-list",
                                _vm._l(_vm.gradesList, function (gradeItem) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: gradeItem.value,
                                      class: {
                                        "primary--text v-list-item--active v-list-item--link theme--light v-list-item--highlighted":
                                          _vm.showLevel.value ==
                                          gradeItem.value,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.updateShowLevel(gradeItem)
                                        },
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(gradeItem.text) + " ")]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "pr-0",
                  style: {
                    "padding-bottom": _vm.horizontalLayout
                      ? "0px"
                      : _vm.mobileLayout
                      ? "5px"
                      : "15px",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "drag-wrapper",
                      style: { "max-height": _vm.wrapperHeight },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "title" },
                        [
                          !_vm.mobileLayout
                            ? _c("v-col", { attrs: { cols: "3" } })
                            : _vm._e(),
                          _c(
                            "v-col",
                            { attrs: { cols: _vm.mobileLayout ? "8" : "6" } },
                            [
                              _vm.$currentUser.isTeacher
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "header",
                                      attrs: {
                                        title: _vm
                                          .$t("isAssignedClass")
                                          .toString(),
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("isAssignedClass")))]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass: "header",
                                      attrs: {
                                        title: _vm
                                          .$t("isAssignedTeacher")
                                          .toString(),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("isAssignedTeacher"))
                                      ),
                                    ]
                                  ),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "right",
                              attrs: { cols: _vm.mobileLayout ? "4" : "3" },
                            },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: { "offset-y": "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      color: "white",
                                                      text: "",
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "mr-1" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("removeLabel")
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm.horizontalLayout
                                                  ? _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [
                                                        _vm._v(
                                                          "fa-solid fa-arrow-right"
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [
                                                        _vm._v(
                                                          "fa-solid fa-arrow-down"
                                                        ),
                                                      ]
                                                    ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    429008745
                                  ),
                                },
                                [
                                  _c(
                                    "v-list",
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.removeAll.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-title",
                                            { staticClass: "drop-down-list" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("allStudentsLabel")
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        { on: { click: _vm.removeSelected } },
                                        [
                                          _c(
                                            "v-list-item-title",
                                            { staticClass: "drop-down-list" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "selectedStudentsLabel"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-text-field", {
                        staticClass: "mx-2",
                        attrs: {
                          clearable: "",
                          "single-line": "",
                          "hide-details": "",
                          "prepend-inner-icon": "fal fa-search",
                          label: _vm.$t("searchLabel"),
                          "aria-label": _vm.$t("searchAsssignedLabel"),
                        },
                        model: {
                          value: _vm.searchAssigned,
                          callback: function ($$v) {
                            _vm.searchAssigned = $$v
                          },
                          expression: "searchAssigned",
                        },
                      }),
                      _c("v-data-table", {
                        attrs: {
                          headers: _vm.filteredHeaders,
                          search: _vm.searchAssigned,
                          items: _vm.assignedStudents,
                          "item-key": "studentId",
                          height: _vm.tableHeight,
                          "fixed-header": "",
                          "show-select": "",
                          "items-per-page": -1,
                          "hide-default-footer": "",
                          "mobile-breakpoint": "0",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "body",
                              fn: function ({ items, isSelected, select }) {
                                return [
                                  _c(
                                    "draggable",
                                    {
                                      attrs: {
                                        options: { forceFallback: true },
                                        group: "student",
                                        tag: "tbody",
                                        sort: false,
                                      },
                                      on: { end: _vm.unassignStudent },
                                      model: {
                                        value: _vm.assignedStudents,
                                        callback: function ($$v) {
                                          _vm.assignedStudents = $$v
                                        },
                                        expression: "assignedStudents",
                                      },
                                    },
                                    _vm._l(items, function (student) {
                                      return _c(
                                        "tr",
                                        { key: student.studentId },
                                        [
                                          _c(
                                            "td",
                                            [
                                              _c("v-checkbox", {
                                                staticStyle: {
                                                  margin: "0px",
                                                  padding: "0px",
                                                },
                                                attrs: {
                                                  "input-value":
                                                    isSelected(student),
                                                  color: !_vm.$vuetify.theme
                                                    .dark
                                                    ? "#7a7a7a"
                                                    : "#ffffff",
                                                  "hide-details": "",
                                                  "aria-label":
                                                    _vm.getStudentLabel(
                                                      student
                                                    ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    select(
                                                      student,
                                                      !isSelected(student)
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(student.lastName) +
                                                " "
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(student.firstName) +
                                                " "
                                            ),
                                          ]),
                                          _c("td", [
                                            student.authorType == "T"
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("teacherLabel")
                                                    )
                                                  ),
                                                ])
                                              : _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("schoolLabel")
                                                    )
                                                  ),
                                                ]),
                                          ]),
                                          _vm.haveRosteredItems &&
                                          _vm.$currentUser.isTeacher
                                            ? _c(
                                                "td",
                                                [
                                                  _c(
                                                    "rostered-item-indicator",
                                                    {
                                                      key:
                                                        student.studentId +
                                                        student.subjectId +
                                                        student.isItemRostered,
                                                      attrs: {
                                                        itemType:
                                                          "studentenrollment",
                                                        item: student,
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1522042467
                        ),
                        model: {
                          value: _vm.selectedAssignedRows,
                          callback: function ($$v) {
                            _vm.selectedAssignedRows = $$v
                          },
                          expression: "selectedAssignedRows",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c("v-col", { staticClass: "pl-0" }, [
                _c(
                  "div",
                  {
                    staticClass: "drag-wrapper",
                    style: { "max-height": _vm.wrapperHeight },
                  },
                  [
                    _c(
                      "v-row",
                      { staticClass: "title" },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "left",
                            attrs: { cols: _vm.mobileLayout ? "4" : "3" },
                          },
                          [
                            _c(
                              "v-menu",
                              {
                                attrs: { "offset-y": "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    color: "white",
                                                    text: "",
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _vm.horizontalLayout
                                                ? _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [
                                                      _vm._v(
                                                        "fa-solid fa-arrow-left"
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [
                                                      _vm._v(
                                                        "fa-solid fa-arrow-up"
                                                      ),
                                                    ]
                                                  ),
                                              _c(
                                                "span",
                                                { staticClass: "ml-1" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("assignLabel")
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4115602460
                                ),
                              },
                              [
                                _c(
                                  "v-list",
                                  [
                                    _c(
                                      "v-list-item",
                                      { on: { click: _vm.assignAll } },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          { staticClass: "drop-down-list" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("allStudentsLabel"))
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item",
                                      { on: { click: _vm.assignSelected } },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          { staticClass: "drop-down-list" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("selectedStudentsLabel")
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: _vm.mobileLayout ? "8" : "6" } },
                          [
                            _vm.$currentUser.isTeacher
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "header",
                                    attrs: {
                                      title: _vm
                                        .$t("isUnassignedClass")
                                        .toString(),
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("isUnassignedClass")))]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass: "header",
                                    attrs: {
                                      title: _vm
                                        .$t("isUnassignedTeacher")
                                        .toString(),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("isUnassignedTeacher"))
                                    ),
                                  ]
                                ),
                          ]
                        ),
                        !_vm.mobileLayout
                          ? _c("v-col", { attrs: { cols: "3" } })
                          : _vm._e(),
                        _c("v-spacer"),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "mx-3" },
                      [
                        _c("v-text-field", {
                          staticClass: "mx-2",
                          attrs: {
                            clearable: "",
                            "single-line": "",
                            "hide-details": "",
                            "prepend-inner-icon": "fal fa-search",
                            label: _vm.$t("searchLabel"),
                            "aria-label": _vm.$t("searchUnasssignedLabel"),
                          },
                          model: {
                            value: _vm.searchUnassigned,
                            callback: function ($$v) {
                              _vm.searchUnassigned = $$v
                            },
                            expression: "searchUnassigned",
                          },
                        }),
                        _c("v-data-table", {
                          attrs: {
                            headers: _vm.filteredHeaders,
                            search: _vm.searchUnassigned,
                            items: _vm.filteredUnassignedStudents,
                            "item-key": "studentId",
                            height: _vm.tableHeight,
                            "fixed-header": "",
                            "show-select": "",
                            "items-per-page": -1,
                            "hide-default-footer": "",
                            "mobile-breakpoint": "0",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "body",
                                fn: function ({ items, isSelected, select }) {
                                  return [
                                    _c(
                                      "draggable",
                                      {
                                        attrs: {
                                          options: { forceFallback: true },
                                          group: "student",
                                          tag: "tbody",
                                          sort: false,
                                        },
                                        on: { end: _vm.assignStudent },
                                        model: {
                                          value: _vm.filteredUnassignedStudents,
                                          callback: function ($$v) {
                                            _vm.filteredUnassignedStudents = $$v
                                          },
                                          expression:
                                            "filteredUnassignedStudents",
                                        },
                                      },
                                      _vm._l(items, function (student) {
                                        return _c(
                                          "tr",
                                          { key: student.studentId },
                                          [
                                            _c(
                                              "td",
                                              [
                                                _c("v-checkbox", {
                                                  staticStyle: {
                                                    margin: "0px",
                                                    padding: "0px",
                                                  },
                                                  attrs: {
                                                    "input-value":
                                                      isSelected(student),
                                                    color: !_vm.$vuetify.theme
                                                      .dark
                                                      ? "#7a7a7a"
                                                      : "#ffffff",
                                                    "hide-details": "",
                                                    "aria-label":
                                                      _vm.getStudentLabel(
                                                        student
                                                      ),
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      select(
                                                        student,
                                                        !isSelected(student)
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c("td", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(student.lastName) +
                                                  " "
                                              ),
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(student.firstName) +
                                                  " "
                                              ),
                                            ]),
                                            _c("td", [
                                              student.authorType == "T"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("teacherLabel")
                                                      )
                                                    ),
                                                  ])
                                                : _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("schoolLabel")
                                                      )
                                                    ),
                                                  ]),
                                            ]),
                                            _vm.haveRosteredItems &&
                                            _vm.$currentUser.isTeacher
                                              ? _c(
                                                  "td",
                                                  [
                                                    _c(
                                                      "rostered-item-indicator",
                                                      {
                                                        key:
                                                          student.studentId +
                                                          student.subjectId +
                                                          student.isItemRostered,
                                                        attrs: {
                                                          itemType:
                                                            "studentenrollment",
                                                          item: student,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      }),
                                      0
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3132680162
                          ),
                          model: {
                            value: _vm.selectedUnassignedRows,
                            callback: function ($$v) {
                              _vm.selectedUnassignedRows = $$v
                            },
                            expression: "selectedUnassignedRows",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c("pb-confirm", { ref: "confirm" }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }