var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "pb-base-modal",
    {
      attrs: {
        maxWidth: 700,
        expandable: false,
        withActions: false,
        withApply: false,
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [_vm._v(_vm._s(_vm.$t("generatedCodesLabel")))]
          },
          proxy: true,
        },
        {
          key: "bottom-actions",
          fn: function () {
            return [
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { on: { click: _vm.emailCodes } },
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v("fal fa-envelope"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("emailToLabel")) + " "),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    { on: { click: _vm.copyCodes } },
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v("fal fa-copy"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("copyClipboardLabel")) + " "),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    { on: { click: _vm.downloadCodes } },
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v("fal fa-download"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("downloadLinkLabel")) + " "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.localValue,
        callback: function ($$v) {
          _vm.localValue = $$v
        },
        expression: "localValue",
      },
    },
    [
      _vm.viewMode == "T"
        ? _c(
            "v-container",
            { staticClass: "px-5" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-switch", {
                        staticClass: "mt-0",
                        attrs: {
                          "hide-details": "auto",
                          color: "blue darken-1",
                          inset: "",
                          label: _vm.$t("studentCreationLabel"),
                        },
                        on: { click: _vm.toggleStudentCreation },
                        model: {
                          value: _vm.studentAccountsEnabled,
                          callback: function ($$v) {
                            _vm.studentAccountsEnabled = $$v
                          },
                          expression: "studentAccountsEnabled",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.studentAccountsPrevented
                    ? _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        !_vm.studentAccountsEnabled
                                          ? _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {},
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v("fal fa-circle-info")]
                                            )
                                          : _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  { attrs: { color: "red" } },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  "fal fa-triangle-exclamation"
                                                ),
                                              ]
                                            ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1462628369
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("newAccountsDisabledLabel"))
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-data-table", {
                    attrs: {
                      headers: _vm.headersJoin,
                      items: _vm.classes,
                      "show-select": true,
                      "item-key": "code",
                      "fixed-header": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.data-table-select",
                          fn: function ({ isSelected, select }) {
                            return [
                              _c("v-checkbox", {
                                staticStyle: { margin: "0px", padding: "0px" },
                                attrs: {
                                  "input-value": isSelected,
                                  color: !_vm.$vuetify.theme.dark
                                    ? "#7a7a7a"
                                    : "#ffffff",
                                  "hide-details": "",
                                },
                                on: {
                                  change: function ($event) {
                                    return select($event)
                                  },
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      647977237
                    ),
                    model: {
                      value: _vm.selectedRows,
                      callback: function ($$v) {
                        _vm.selectedRows = $$v
                      },
                      expression: "selectedRows",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-container",
            {
              staticClass: "px-5",
              staticStyle: { "max-height": "calc(100vh - 130px) !important" },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-switch", {
                        staticClass: "mt-0",
                        attrs: {
                          "hide-details": "auto",
                          color: "blue darken-1",
                          inset: "",
                          label: _vm.$t("studentCreationAdminLabel"),
                        },
                        on: { click: _vm.toggleStudentPrevention },
                        model: {
                          value: _vm.studentAccountsPrevented,
                          callback: function ($$v) {
                            _vm.studentAccountsPrevented = $$v
                          },
                          expression: "studentAccountsPrevented",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "mx-2 mt-1" },
                        [
                          _c(
                            "h4",
                            {
                              staticClass: "my-auto mr-4",
                              staticStyle: { display: "inline" },
                            },
                            [_vm._v(_vm._s(_vm.$t("viewByLabel")))]
                          ),
                          _c(
                            "v-btn-toggle",
                            {
                              staticStyle: { display: "inline" },
                              attrs: {
                                color: "primary",
                                mandatory: "",
                                dense: "",
                              },
                              model: {
                                value: _vm.viewBy,
                                callback: function ($$v) {
                                  _vm.viewBy = $$v
                                },
                                expression: "viewBy",
                              },
                            },
                            [
                              _c("v-btn", { attrs: { value: "Y" } }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("schoolYearLabel"))),
                                ]),
                              ]),
                              _c("v-btn", { attrs: { value: "T" } }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("teacherLabel"))),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.viewBy === "T"
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3" } },
                        [
                          _c("v-select", {
                            attrs: {
                              label: _vm.$t("teacherLabel"),
                              "return-object": "",
                              outlined: "",
                              dense: "",
                              items: _vm.selectOptions.teachers,
                              "item-text": "text",
                              "hide-details": "auto",
                            },
                            model: {
                              value: _vm.selectedTeacher,
                              callback: function ($$v) {
                                _vm.selectedTeacher = $$v
                              },
                              expression: "selectedTeacher",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.viewBy === "T"
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3" } },
                        [
                          _c("v-select", {
                            attrs: {
                              label: _vm.$t("yearLabel"),
                              "return-object": "",
                              outlined: "",
                              dense: "",
                              items: _vm.selectOptions.teacherYears,
                              "item-text": "yearName",
                              "hide-details": "auto",
                            },
                            model: {
                              value: _vm.selectedTeacherYear,
                              callback: function ($$v) {
                                _vm.selectedTeacherYear = $$v
                              },
                              expression: "selectedTeacherYear",
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-select", {
                            attrs: {
                              label: _vm.$t("schoolYearLabel"),
                              "return-object": "",
                              dense: "",
                              items: _vm.selectOptions.adminYears,
                              "item-text": "yearName",
                              outlined: "",
                              "hide-details": "auto",
                            },
                            model: {
                              value: _vm.selectedAdminYear,
                              callback: function ($$v) {
                                _vm.selectedAdminYear = $$v
                              },
                              expression: "selectedAdminYear",
                            },
                          }),
                        ],
                        1
                      ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _vm.viewBy == "Y"
                    ? _c("v-data-table", {
                        attrs: {
                          headers: _vm.headersJoin,
                          items: _vm.joinClassesAdminY,
                          "show-select": true,
                          "item-key": "code",
                          "fixed-header": "",
                          "group-by": "teacher.yearId",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "group.header",
                              fn: function ({ items, isOpen, toggle }) {
                                return [
                                  _c(
                                    "th",
                                    { attrs: { colspan: "4" } },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "focus-icon-border",
                                          attrs: {
                                            "aria-label":
                                              _vm.$t("showContentLabel"),
                                          },
                                          on: { click: toggle },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              isOpen ? "mdi-minus" : "mdi-plus"
                                            ) + " "
                                          ),
                                        ]
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(items[0].teacher.teacherName) +
                                          " : " +
                                          _vm._s(items[0].teacher.yearName) +
                                          " "
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "ml-2 focus-icon-border",
                                          attrs: {
                                            "x-small": "",
                                            "aria-label":
                                              _vm.$t("unselectAllLabel"),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.unselectAll(
                                                items[0].teacher
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("fa-solid fa-x")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item.data-table-select",
                              fn: function ({ isSelected, select }) {
                                return [
                                  _c("v-checkbox", {
                                    staticStyle: {
                                      margin: "0px",
                                      padding: "0px",
                                    },
                                    attrs: {
                                      "input-value": isSelected,
                                      color: !_vm.$vuetify.theme.dark
                                        ? "#7a7a7a"
                                        : "#ffffff",
                                      "hide-details": "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return select($event)
                                      },
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2866350388
                        ),
                        model: {
                          value: _vm.selectedRowsAdminY,
                          callback: function ($$v) {
                            _vm.selectedRowsAdminY = $$v
                          },
                          expression: "selectedRowsAdminY",
                        },
                      })
                    : _c("v-data-table", {
                        attrs: {
                          headers: _vm.headersJoin,
                          items: _vm.joinClassesAdminT,
                          "show-select": true,
                          "item-key": "code",
                          "fixed-header": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item.data-table-select",
                            fn: function ({ isSelected, select }) {
                              return [
                                _c("v-checkbox", {
                                  staticStyle: {
                                    margin: "0px",
                                    padding: "0px",
                                  },
                                  attrs: {
                                    "input-value": isSelected,
                                    color: !_vm.$vuetify.theme.dark
                                      ? "#7a7a7a"
                                      : "#ffffff",
                                    "hide-details": "",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return select($event)
                                    },
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.selectedRowsAdminT,
                          callback: function ($$v) {
                            _vm.selectedRowsAdminT = $$v
                          },
                          expression: "selectedRowsAdminT",
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }